import dayjs from 'dayjs';

export const calculateBountyReward = ({ dateForReward, price }) => {
  const now = dayjs();
  const then = dayjs(dateForReward);
  const minutesFloored = now.diff(then, 'minutes');
  let modifier = 0.0005;
  const reward = minutesFloored * price * modifier;
  if (reward < 10) return 10;
  if (reward > 5000) return 5000;
  return Math.round(reward);
};
