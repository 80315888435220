import Collections from '../components/home/Collections';
import FloorBounties from '../components/home/FloorBounties';
import HomeHero from '../components/home/HomeHero';
import TopSales from '../components/home/TopSales';
import UtilityInfo from '../components/home/UtilityInfo';

export default function Home() {
  return (
    <>
      <HomeHero />
      <Collections />
      <UtilityInfo />
      <FloorBounties />
      <TopSales />
    </>
  );
}
