'use client';

import React from 'react';
import { create } from 'zustand';
import cityBlockIds from '../../data/city-blocks';

const getFourRandomBlocks = () => {
  return cityBlockIds.sort(() => 0.5 - Math.random()).slice(0, 4);
};

const useBlocksToShowStore = create((set) => ({
  blocksToShow: getFourRandomBlocks(),
  setBlocksToShow: (blocks) => set({ blocksToShow: blocks }),
}));

const PreviewBlocks = () => {
  const blocksToShow = useBlocksToShowStore((state) => state.blocksToShow);

  const onRefresh = () => {
    useBlocksToShowStore.setState({ blocksToShow: getFourRandomBlocks() });
  };

  return (
    <div>
      {blocksToShow.length > 0 && (
        <div className='relative col-span-12 md:col-span-7 min-h-[400px] flex items-center flex-col justify-center'>
          <div className='grid grid-cols-4 gap-x-8'>
            <div className='col-span-2 col-start-2 -mb-24 sm:-mb-36 lg:-mb-48'>
              <div>
                <img src={`/blocks/builder/${blocksToShow[0]}.png`} alt='example 3' />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-x-8'>
            <div className='md:block hidden lg:hidden'></div>
            <div className='md:block hidden lg:hidden'></div>
            <div className='block md:hidden lg:block col-span-2'>
              <div>
                <img src={`/blocks/builder/${blocksToShow[1]}.png`} alt='example 2' />
              </div>
            </div>
            <div className='col-span-2'>
              <div>
                <img src={`/blocks/builder/${blocksToShow[2]}.png`} alt='example 3' />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-x-5'>
            <div className='col-span-2 col-start-2 -mt-24 sm:-mt-36 lg:-mt-48'>
              <div>
                <img src={`/blocks/builder/${blocksToShow[3]}.png`} alt='example 3' />
              </div>
            </div>
          </div>

          <div className='absolute top-5 right-0 z-20'>
            <button type='button' className='p-2 group active:opacity-50' onClick={onRefresh}>
              <span className='sr-only'>Refresh</span>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-6 h-6 group-hover:rotate-180 transition duration-300'>
                <path
                  d='M94 187.1C120.8 124.1 183.3 80 256 80c39.7 0 77.8 15.8 105.9 43.9L414.1 176 360 176c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 54.1L395.9 89.9C358.8 52.8 308.5 32 256 32C163.4 32 83.9 88.2 49.8 168.3c-5.2 12.2 .5 26.3 12.7 31.5s26.3-.5 31.5-12.7zm368 157c5.2-12.2-.4-26.3-12.6-31.5s-26.3 .4-31.5 12.6C391 388.1 328.6 432 256 432c-39.7 0-77.8-15.8-105.9-43.9L97.9 336l54.1 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L40 288c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-54.1 52.1 52.1C153.2 459.2 203.5 480 256 480c92.5 0 171.8-56 206-135.9z'
                  fill='currentColor'
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewBlocks;
