const assets = [
  1102790446, 1102790477, 1102790500, 1102790541, 1102790598, 1102790639, 1102790776, 1102790795, 1102790905, 1102790977, 1102790992, 1102791038,
  1102791078, 1102791159, 1102791189, 1102791379, 1102791494, 1102791601, 1102791625, 1102791660, 1102791858, 1102791933, 1102791944, 1102791953,
  1102792040, 1102792150, 1102792170, 1102792258, 1102792373, 1102792449, 1102792471, 1102792614, 1102792698, 1102792760, 1102792839, 1102792942,
  1102792980, 1102793002, 1102793069, 1102793109, 1102793144, 1102793193, 1102793225, 1102793315, 1102793354, 1102793493, 1102793536, 1102793572,
  1102793644, 1102793926, 1102794118, 1102794151, 1102794167, 1102794250, 1102794290, 1102794370, 1102794412, 1102794495, 1102794533, 1102794582,
  1102794673, 1102794695, 1102794767, 1102794798, 1102794904, 1102795000, 1102795063, 1102795090, 1102795144, 1102795248, 1102795263, 1102795344,
  1102795413, 1102795458, 1102795494, 1102795560, 1102795574, 1102795644, 1102795734, 1102795784, 1102795798, 1102795853, 1102795893, 1102795913,
  1102795932, 1102795975, 1102796063, 1102796093, 1102796135, 1102796177, 1102796191, 1102796245, 1102796294, 1102796379, 1102796409, 1102796452,
  1102796494, 1102796553, 1102796577, 1102796597, 1102796615, 1102796652, 1102796681, 1102796708, 1102796797, 1102796823, 1102796840, 1102797240,
  1102797274, 1102797363, 1102797371, 1102797432, 1102797474, 1102797506, 1102797516, 1102797547, 1102797573, 1102797697, 1102797727, 1139438477,
  1149446294, 1152419850, 1152429753, 1158780700, 1164204874, 1169314818, 1181282029, 1181283955, 1184888784, 1186625328, 1189235881, 1189236473,
  1195948055, 1195948753, 1200067279, 1200071517, 1200073656, 1205905668, 1205905872, 1213224198, 1215951339, 1215952237, 1222782875, 1224015847,
  1224017095, 1234961202, 1234961801, 1246271291, 1246273768, 1253758995, 1253764086, 1256934842, 1272214008, 1290413684, 1290914114, 1391613207,
  1400406092, 1409226592, 1409229149, 1409234707, 1409235706, 1433051123, 1502814028, 1593685162, 1708657386, 1761001379, 2179937285,
];

export default assets;
