import axios from 'axios';

export const getNfd = async (address) => {
  try {
    const { data } = await axios.get(`https://api.nf.domains/nfd/address?address=${address}`);
    const [shittyNfd] = data.filter((nfd) => nfd.name.includes('shitty') || nfd.name.includes('shittykitties'));
    if (shittyNfd) {
      return shittyNfd;
    } else {
      const { data } = await axios.get(`https://api.nf.domains/nfd/lookup?address=${address}&limit=1&view=full`);
      const nfd = data[address];
      return nfd;
    }
  } catch (error) {
    return null;
  }
};
