import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getNfd } from '../utils/getNfd';

function useNfd(props) {
  const getAddress = async (nfd) => {
    try {
      const { data } = await axios.get(`https://api.nf.domains/nfd/${nfd}`);
      return data;
    } catch (error) {
      return null;
    }
  };

  const query = useQuery({
    queryKey: ['nfd', props?.address],
    queryFn: () => getNfd(props?.address),
    enabled: !!props?.address,
  });
  const nfd = query.data;

  return { getNfd, getAddress, nfd };
}

export default useNfd;
